import React, { useState, useEffect, useCallback } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";

import { Organization } from "modules/api/endpoints/organizationGet";

import { api, API } from "modules/api";
import { AssessmentGetResponse } from "modules/api/endpoints/assessmentGet";

import { ErrorMessagesType, assessmentErrorMessages } from "modules/messages";

import styles from "../styles.module.scss";

import Template from "./Template";

import { useModal } from "modules/hooks/modal";
import { ButtonStyle } from "components/Button";
import UseLockAssessment from "routes/questions/hooks/useLockAssessment";
import { getErrorMessages } from "modules/messages/helpers";
import { unarchiveAssessment } from "modules/utils/ArchiveAssessments";

type questiosnParam = {
  assessmentId: string;
};

type AssessmentResponse = {
  isTestAssessment: boolean;
  certified: boolean;
  endorsed: boolean;
  selectedScope: string;
  dateCompleted: string;
  dateStarted: string;
  id: number;
  name: string;
  status: string;
  isArchived: boolean;
  capabilities: Items[];
  maturities: Items[];
  organization: Organization;
};

export type Items = {
  id: number;
  code: string;
  name: string;
  isSelected: boolean;
};

export type ProgressItem = {
  id: number;
  name: string;
  code: string;
  started: boolean;
  currentScore: number;
  mode?: number;
  complete: boolean;
};

export type ProgressType = {
  id: number;
  name: string;
  maturityScore: number;
  capabilityProgress: {
    code: string;
    complete: boolean;
    currentScore: number;
    id: number;
    mode: number;
    name: string;
    started: boolean;
  }[];
  maturityProgress: {
    code: string;
    complete: boolean;
    currentScore: number;
    id: number;
    name: string;
    started: boolean;
  }[];
};

export type ProgressFEType = {
  id: number;
  perspective: string;
  code: string;
  answeredThreads: number;
  threads: ProgressItem[];
  currentScore: number;
  totalTreads: number;
};

const QuestionsItilSummary: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState<AssessmentResponse>(
    {} as AssessmentResponse
  );
  const [progress, setProgress] = useState<ProgressType>({} as ProgressType);
  const [isAssessmentSubmitable, setIsAssessmentSubmitable] = useState(false);
  const [isSubmitingAssessment, setIsSubmitingAssessment] = useState(false);

  const submitConfirmation = useModal();

  const {
    params: { assessmentId },
  } = useRouteMatch<questiosnParam>();
  const history = useHistory();

  const handleNavegate = (code: string) => {
    history.push(`/itil/assessments/${assessment.id}/questions/${code}`);
  };

  const handleCertify = () => {
    history.push(`/assessments/${assessment.id}/profile/itil`);
  };
  const token = sessionStorage.getItem("@P3M3:token");
  // Get User Session Token
  let parsedToken = null;
  if (token) {
    parsedToken = JSON.parse(token);
  }
  const data = { authorization: parsedToken.token };
  const url = API.POST_DELETE_ASSESSMENT_LOCK(Number(assessmentId)).url;
  // Set request content type
  const headers = {
    type: "application/json",
  };
  // set request data
  const blob = new Blob([JSON.stringify(data)], headers);
  // Setup beacon request
  const eventHandler = () => {
    sessionStorage.removeItem(`@P3M3:lock:${assessmentId}`);
    navigator.sendBeacon(url, blob);
  };
  // Check if assessment has been locked already
  const handleIsLocked = async (id: number) => {
    try {
      const response = await api(API.POST_ASSESSMENT_LOCK(id)).then(
        (res) => res.data
      );
      if (response) {
        return true;
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        history.push("/");
        sessionStorage.removeItem(`@P3M3:lock:${assessmentId}`);
        return false;
      }
    }
    return false;
  };
  // Handle user lock
  const checkLockSession = async () => {
    if (sessionStorage.getItem(`@P3M3:lock:${assessmentId}`) === null) {
      sessionStorage.setItem(`@P3M3:lock:${assessmentId}`, "1");
      const response = await handleIsLocked(Number(assessmentId));
      if (response) {
        return true;
      }
    }
  };
  useEffect(() => {
    checkLockSession();
    window.addEventListener("beforeunload", eventHandler, true);
    return () => {
      window.removeEventListener("beforeunload", eventHandler, true);
    };
  }, []);
  useEffect(() => {
    let isCancelled = false;

    if (!isAssessmentValid(assessmentId)) {
      return history.push("/");
    }

    const fetchAssessment = async () => {
      try {
        const [assessmentProgress, assessmentData] = await Promise.all([
          api(API.GET_ASSESSMENT_PROGRESS(assessmentId)).then(
            (res) => res.data
          ),
          api(API.GET_ASSESSMENT(assessmentId)).then((res) => res.data),
        ]);

        if (!assessmentData && isCancelled) history.push("/");

        setIsAssessmentSubmitable(() => {
          const capabilityAndMaturityItems = [
            ...assessmentProgress.capabilityProgress,
            ...assessmentProgress.maturityProgress,
          ];

          return capabilityAndMaturityItems.every((i) => i.complete);
        });
        setProgress(assessmentProgress);
        setAssessment(assessmentData);
        setIsLoading(false);
      } catch (error: any) {
        const messages = getErrorMessages(
          assessmentErrorMessages,
          error?.response?.status
        );
        await submitConfirmation({
          type: "error",
          catchOnCancel: true,
          title: messages.title,
          description: messages.description,
        }).finally(() => apiErrorRedirection(error?.response?.status));
      }
    };

    fetchAssessment();

    return () => {
      isCancelled = true;
      const unlockAssessment = UseLockAssessment(
        history.location.pathname,
        Number(assessmentId)
      );
      unlockAssessment();
    };
  }, []);

  const apiErrorRedirection = (error = 400) => {
    if (error && error === 401) {
      window.location.assign("https://www.axelos.com/");
      return;
    }
    history.push("/");
  };

  const isAssessmentValid = useCallback((assemt: string) => {
    return !!assemt;
  }, []);

  const getStatusClass = useCallback((item: ProgressItem) => {
    if (!item.started) return styles.notAnswered;
    if (item.started && item.complete) return styles.answered;
    return styles.warning;
  }, []);

  const handleSubmitableAssessment = (items: ProgressItem[]) => {
    return items.every((i) => i.complete);
  };
  const handleSubmitAssessment = async () => {
    setIsSubmitingAssessment(true);
    try {
      const confirmationResponse = await submitConfirmation({
        type: "info",
        catchOnCancel: true,
        title: "Are you ready to Submit?",
        description:
          "Once you have submitted this assessment you cannot make any changes but will have access to a read-only copy.",
        buttonLabel: "Submit assessment",
        displayCancelButton: true,
      })
        .then(() => true)
        .catch(() => {
          setIsSubmitingAssessment(false);
          return false;
        });

      if (!confirmationResponse) return;

      const isSubmitted = await api(API.POST_ASSESSMENT_COMPLETE(assessmentId));

      if (isSubmitted) {
        await submitConfirmation({
          type: "success",
          title: "You will now view the results of this assessment.",
          description: assessment.endorsed
            ? "A request for endorsment has been sent out and you will receive the response shortly."
            : "",
          buttonLabel: "Continue",
          buttonStyle: ButtonStyle.SECONDARY,
        });
        UseLockAssessment(history.location.pathname, Number(assessmentId));
        history.push(`/assessments/${assessmentId}/result/itil`);
      }
    } catch (error) {
      submitConfirmation({
        type: "error",
        title: "Something happened, please try again...",
      });

      setIsSubmitingAssessment(false);
    }
  };

  const handleUnarchive = async () => {
    await unarchiveAssessment(assessment.id);
    history.push(`/`);
  };

  return (
    <Template
      isLoading={isLoading}
      isArchived={assessment.isArchived}
      organization={assessment.organization}
      scope={assessment.selectedScope}
      name={assessment.name}
      id={assessment?.id?.toString()}
      isTestAssessment={assessment?.isTestAssessment?.toString()}
      displayCertifyAssessment={!assessment.endorsed}
      handleNavegate={handleNavegate}
      handleCertify={handleCertify}
      progress={progress}
      isAssessmentSubmitable={isAssessmentSubmitable}
      isSubmitingAssessment={isSubmitingAssessment}
      handleSubmitAssessment={handleSubmitAssessment}
      isAssessmentLocked={assessment.status}
      handlePracticeStatus={getStatusClass}
      handleUnarchive={handleUnarchive}
    />
  );
};

export default QuestionsItilSummary;
