export type AssessmentType = {
  P3M3: AssessmentScope;
  ITIL: AssessmentScope;
};

export enum AssessmentFormat {
  P3M3 = "P3M3",
  ITIL = "ITIL",
}

export type PerspectiveType = {
  answeredQuestions: number;
  id: number;
  name: string;
  totalQuestions: number;
  code: string;
};

export type ThreadType = {
  code: string;
  id: number;
  isSelected: boolean;
  name: string;
};

export type Assessment = {
  isTestAssessment: boolean;
  certified: boolean;
  endorsed: boolean;
  selectedScope: string;
  dateCompleted: string;
  dateArchived: string;
  dateStarted: string;
  id: number;
  name: string;
  perspectives: PerspectiveType[];
  threads: ThreadType[];
  status: string;
  isArchived: boolean;
  capabilities: PerspectiveType[];
  maturities: PerspectiveType[];
  createdBy?: string;
  locked: boolean;
};

export enum tabOptions {
  Active = "ACTIVE",
  Completed = "COMPLETED",
  Archive = "ARCHIVE",
}

export type AssessmentScope = {
  active: Assessment[];
  archived: Assessment[];
  completed: Assessment[];
  created: Assessment[];
  drafted: Assessment[];
};

export enum AssessmentState {
  ACTIVE = "Active",
  CREATED = "Created",
  DRAFT = "Drafted",
  COMPLETED = "Completed",
}
