import React, { InputHTMLAttributes } from "react";

import styles from "./styles.module.scss";

type OptionType = {
  label: string;
  value: string;
};

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options?: OptionType[];
  label?: string;
  description?: string;
  readonly?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => (
    <label
      className={`${styles.label} ${
        props.description ? styles.largetext : ""
      } ${props.readonly ? styles.disabled : ""}`}
      htmlFor={props.id}
    >
      {props.label}
      <input tabIndex={0} ref={ref} {...props} />
      <span
        className={props.label === "" ? "isReadOnlyCheckbox" : ""}
        style={{ border: props.label === "" ? "0px" : "1px" }}
      ></span>
    </label>
  )
);

export default React.memo(Checkbox);
