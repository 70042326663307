import React from "react";

import * as TableSet from "components/TableSet";
import * as Charts from "components/Charts";

import Section from "components/Section";
import Sidebar from "components/Sidebar";
import Loading from "components/Loading";
import Button, { ButtonStyle, ButtonType } from "components/Button";
import { ReactComponent as AreaImprovementSVG } from "modules/theme/icons/icon-exclamation-mark.svg";

import { ImprovmentsItem } from "modules/api/endpoints/assessmentResultGet";
import { Organization } from "modules/api/endpoints/organizationGet";

import { ChartData } from "components/Charts/Radar";
import Headings from "components/Headings";
import { TableItilType } from "./index";
import { ReactComponent as DownloadSVG } from "modules/theme/icons/buttons/download.svg";
import styles from "./styles.module.scss";

const chartOptions = {
  scale: {
    ticks: { beginAtZero: true, max: 5 },
    pointLabels: {
      fontSize: 12,
    },
  },
  legend: {
    display: false,
  },
};

type TemplateProps = {
  isLoading: boolean;
  organization: Organization;
  finalScore: number;
  id: string;
  isTestAssessment: boolean;
  isEndorsed: boolean;
  handleViewFullAssessment: () => void;
  assessmentName: string;
  capabilityChart: ChartData | null;
  primaryCapabilityTable: TableItilType[];
  supportingCapabilityTable: TableItilType[];
  maturityChart: ChartData | null;
  maturityTable: TableItilType[];
  setActiveTab: (tab: string) => void;
  activeTab: string;
  improvementAreas: ImprovmentsItem[];
  assessmentScope: string;
  notes: {
    assessment: string;
    body: string;
    id: number;
    maturity: string;
  }[];
  exportToElsx: () => void;
};

const Template: React.FunctionComponent<TemplateProps> = ({
  isLoading,
  id,
  isTestAssessment,
  organization,
  handleViewFullAssessment,
  assessmentName,
  maturityChart,
  capabilityChart,
  isEndorsed,
  finalScore,
  maturityTable,
  primaryCapabilityTable,
  supportingCapabilityTable,
  setActiveTab,
  activeTab,
  improvementAreas,
  assessmentScope,
  notes,
  exportToElsx,
}) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <div className={styles.itilResultsPage}>
          <div className={styles.resultsTop}>
            <Headings
              title={assessmentName}
              subtitle={assessmentScope}
            ></Headings>
            <div style={{ marginTop: "4px" }}>
              <h4>Id - {id}</h4>
              <h4>Is Test Assessment - {isTestAssessment.toString()}</h4>
            </div>
            <div className={styles.topCtaContainer}>
              <Button
                label="View full assessment"
                style={ButtonStyle.PRIMARY}
                type={ButtonType.BUTTON}
                onClick={handleViewFullAssessment}
              />
              <Button
                label="Export to Excel"
                style={ButtonStyle.SECONDARY}
                type={ButtonType.BUTTON}
                onClick={exportToElsx}
              >
                <DownloadSVG />
              </Button>
            </div>
          </div>
          <div className={styles.tabContainer}>
            <ul>
              <li
                className={activeTab === "Results" ? styles.active : ""}
                onClick={() => setActiveTab("Results")}
              >
                Results
              </li>
              <li
                className={activeTab === "Improvement" ? styles.active : ""}
                onClick={() => setActiveTab("Improvement")}
              >
                Areas of Improvement
              </li>
            </ul>
          </div>
          <div className={styles.tabContent}>
            {activeTab === "Results" && (
              <>
                <Section small>
                  <Charts.Container>
                    <Charts.Header organization={organization} />
                    {maturityChart && maturityChart.labels.length > 0 && (
                      <Charts.Item
                        title="Maturity Score"
                        gmTagId={"maturity-radar-chart"}
                      >
                        <Charts.Radar
                          data={maturityChart}
                          options={chartOptions}
                        />
                      </Charts.Item>
                    )}
                    {capabilityChart && (
                      <Charts.Item
                        title="Capability Score"
                        gmTagId={"capability-radar-chart"}
                      >
                        <Charts.Radar
                          data={capabilityChart}
                          options={chartOptions}
                        />
                      </Charts.Item>
                    )}
                  </Charts.Container>
                </Section>
                <Sidebar large>
                  {maturityTable.length > 0 && (
                    <>
                      <TableSet.Table
                        hasHeading="Final Score"
                        isCertified={isEndorsed}
                        noBg
                        tblSmall
                        rowSingleScore={finalScore}
                      />
                      <TableSet.Table
                        hasHeading="Maturity results"
                        hasFullBg
                        canDownload
                        thead={["Components", "Score"]}
                        rowsItils={maturityTable}
                        gmTagId={"maturity-scores"}
                        hasScore
                      />
                    </>
                  )}
                  {primaryCapabilityTable.length > 0 && (
                    <TableSet.Table
                      hasHeading="Primary Capabilities results"
                      hasFullBg
                      canDownload
                      thead={["Practice", "Score"]}
                      rowsItils={primaryCapabilityTable}
                      gmTagId={"primary-capability-scores"}
                      hasScore
                    />
                  )}

                  {supportingCapabilityTable.length > 0 && (
                    <TableSet.Table
                      hasHeading="Supporting Capabilities results"
                      hasFullBg
                      canDownload
                      thead={["Practice", "Score"]}
                      rowsItils={supportingCapabilityTable}
                      gmTagId={"supporting-capability-scores"}
                      hasScore
                    />
                  )}
                </Sidebar>
              </>
            )}

            {activeTab === "Improvement" && (
              <Section large>
                <Charts.Container>
                  <Charts.Header organization={organization} />
                  <div style={{ padding: 20 }}>
                    <div className={styles.improvement}>
                      <AreaImprovementSVG />
                      <h3>Areas of improvement</h3>
                    </div>

                    {improvementAreas.length > 0 &&
                      improvementAreas.map((i) => {
                        const improvements = i.questions.map((q) => {
                          const {
                            id,
                            psf,
                            statement: criterion,
                            dimension,
                          } = q;
                          return {
                            id,
                            dimension,
                            psf,
                            criterion,
                          };
                        });
                        return (
                          <TableSet.Table
                            key={i.id}
                            hasHeading={i.name}
                            thead={["Criterion", "Dimension", "PSF"]}
                            rowsItilsImprovements={improvements}
                            canDownload
                            note={i.note ? i.note.body : "Not Provided"}
                            noBorder
                          />
                        );
                      })}

                    {notes.length > 0 &&
                      notes.map((n) => (
                        <TableSet.Table
                          key={n.id}
                          hasHeading={n.maturity}
                          canDownload
                          note={n.body ? n.body : "Not Provided"}
                          noBorder
                        />
                      ))}
                  </div>
                </Charts.Container>
              </Section>
            )}
          </div>
        </div>
      )}
      {isLoading && <Loading noBackground></Loading>}
    </React.Fragment>
  );
};

export default Template;
